import { Application } from './Application'
import './portal/service/create'
import './portal/status/home'
import './vehicle/glass/home'
import './vehicle/glass/order'
import './vehicle/glass/mobile'
import './vehicle/suspension/consumer'
import './vehicle/suspension/manager'
import React, { useState } from 'react'
import ReactDOM from 'react-dom'

Application.refresh()

function FMCMThing() {
    const [accepted, setAccepted] = useState(false)

    function accept() {
        setAccepted(true)
        document.cookie='fmcm=yup'
    }

    if (accepted) return <></>

    return <div style={{ position: 'fixed', width: '100%', bottom: '0px', zIndex: 10000  }}>
        <div className='well well-lg' style={{ marginBottom: '20px', marginLeft: 'auto', marginRight: 'auto', maxWidth: '500px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
            <strong>Avertissement</strong>
            <div className='clearfix'></div>
            <span id="fmcm-message">Les site web utilisent des témoins (cookies) pour fonctionner correctement. Voulez-vous continuer?</span>
            <div className='clearfix'></div>
            <div className='pull-right'>
                <button className='btn btn-primary' onClick={ ()=>accept() }>Oui</button>
                <a href="https://google.com" className='btn btn-cancel'>Non</a>
            </div>
            <div className='clearfix'></div>
        </div>
        
    </div>
}


window.addEventListener('load', (event) => {
    const fmcmElement = document.querySelector('.fmcm')

    if (fmcmElement !== null) {
        if (document.cookie.indexOf('fmcm=') === -1) {
            ReactDOM.render(<FMCMThing />, fmcmElement)
        }
    }
    
})